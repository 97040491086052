<!--
#***********************************************
#
#      Filename: src/components/ContentHeader/index.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: Content上部搜索框
#        Create: 2021-11-04 09:01:44
# Last Modified: 2021-11-27 18:47:05
#***********************************************
-->
<template>
  <div class="gz-page-header">
    <div class="gz-filter">
      <div class="gz-filter-left">
        <!-- 数据条目数量显示 -->
        <div v-if="settings.left.totalItemShow" class="gz-filter-item gz-filter-item-count">
          数据条目{{ totalItem || 0 }}条
        </div>
        <!-- end 数据条目数量显示 -->
        <!-- 搜索 -->
        <div v-if="settings.left.searchShow" class="gz-filter-item">
          <el-input
            v-model="filterData.searchStr"
            :class="['gz-filter-item', 'gz-search']"
            size="mini"
            :placeholder="settings.left.searchPlaceholder"
            @keyup.enter.native="filterChange">
            <template #append>
              <el-button size="mini" class="gz-search-button" type="primary" @click="filterChange">查询</el-button>
            </template>
          </el-input>
        </div>
        <!-- end 搜索 -->

        <div v-if="settings.left.searchShows" class="gz-filter-item">
          <div>
            <div v-for="(item, key) in settings.searchDateWords.arr" :key="key">
              <div v-if="item.type == 'dateTimeRange'" :key="'dateRange'">
                <div class="gz-dropdown-filter-item">
                  <span class="names">{{ item.title }}</span>
                  <el-date-picker
                    :key="'dateRange' + key"
                    v-model="filterData[item.name]"
                    value-format="timestamp"
                    size="mini"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    type="datetimerange"
                    @keyup.enter.native="filterChange" />
                </div>
              </div>
            </div>
            <el-button size="mini" class="gz-search-buttons" type="primary" @click="filterChange">查询</el-button>
            <el-button size="mini" class="gz-search-btn" type="primary" @click="filterClearAll">重置</el-button>
          </div>
        </div>

        <!-- -->
        <!-- 筛选 toggle -->
        <div v-if="settings.left.filterToggleShow" class="gz-filter-item">
          <el-popover placement="bottom" trigger="click" @hide="handleFilterDropHide">
            <svg-icon
              slot="reference"
              icon-class="filter"
              class="gz-filter-svg-icon"
              :class="{ 'is-active': filterStatus }"
              @click="toggleFilterStatus" />
            <div class="gz-dropdown-filter-wrapper">
              <template v-for="(item, key) in settings.dropDownFilterArray.arr">
                <!-- 输入框 -->
                <div v-if="item.type === 'input'" :key="'input' + key" class="gz-dropdown-filter-item">
                  <el-input
                    v-model="filterData[item.name]"
                    size="mini"
                    :placeholder="item.placeholder"
                    @keyup.enter.native="filterChange" />
                </div>
                <!-- end输入框 -->

                <!-- 列表框 -->
                <div v-if="item.type === 'select'" :key="'select' + key" class="gz-dropdown-filter-item">
                  <el-select
                    v-model="filterData[item.name]"
                    size="mini"
                    :placeholder="item.placeholder"
                    :clearable="true"
                    @clear="filterClear(item.name)"
                    @keyup.enter.native="filterChange">
                    <el-option
                      v-for="itemOption in item.options"
                      :key="itemOption.label"
                      :label="itemOption.label"
                      :value="itemOption.value" />
                  </el-select>
                </div>
                <!-- end筛选框 -->

                <!-- 时间段筛选框 -->
                <div v-if="item.type === 'dateRange'" :key="'daterange' + key" class="gz-dropdown-filter-item">
                  <el-date-picker
                    :key="'daterange' + key"
                    v-model="filterData[item.name]"
                    value-format="timestamp"
                    size="mini"
                    start-placeholder="开始日期"
                    type="daterange"
                    end-placeholder="结束日期"
                    @keyup.enter.native="filterChange" />
                </div>
                <!-- end 时间段筛选框 -->

                <!-- 时间段筛选框 -->
                <div v-if="item.type === 'dateTimeRange'" :key="'dateTimeRange' + key" class="gz-dropdown-filter-items">
                  <el-date-picker
                    :key="'dateTimeRange' + key"
                    v-model="filterData[item.name]"
                    value-format="timestamp"
                    size="mini"
                    start-placeholder="开始时间"
                    type="datetimerange"
                    end-placeholder="结束时间"
                    @keyup.enter.native="filterChange" />
                </div>
                <!-- end 时间段筛选框 -->
              </template>

              <div class="gz-dropdown-filter-item">
                <el-button size="mini" type="primary" @click="filterChange">提交</el-button>
              </div>

              <div class="gz-dropdown-filter-item">
                <el-button size="mini" type="primary" @click="filterClearAll">重置</el-button>
              </div>
            </div>
          </el-popover>
        </div>
        <!-- 筛选 toggle -->

        <!-- 表单字段选择 -->
        <div v-if="settings.left.tableHeadToggleShow" class="gz-filter-item">
          <el-dropdown trigger="click" @visible-change="handleFieldDropChange">
            <div class="gz-field-icon-wrapper">
              <svg-icon
                icon-class="item"
                class="gz-filter-svg-icon"
                :class="{ 'is-active': fieldStatus }"
                @click="toggleFieldStatus" />
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item, index) in tableHeadFields" :key="'field' + index">
                <el-checkbox v-model="item.isShow">
                  {{ item.label }}
                </el-checkbox>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- 筛选 toggle -->

        <div class="gz-filter-item">
          <slot name="custom-left" />
        </div>
      </div>

      <div class="gz-filter-right">
        <div v-if="settings.right.refreshShow" class="gz-filter-item">
          <div class="gz-text-btn" @click="handleRefresh">
            <span class="m-r-5">刷新表格</span>
            <i class="el-icon-refresh-right" />
          </div>
        </div>
        <div v-if="settings.right.sortShow" class="gz-filter-item">
          <div class="gz-text-btn">
            <span class="m-r-5">按时间顺序排序</span>
            <i class="el-icon-sort" />
          </div>
        </div>
        <div class="gz-filter-item gz-header-btn-group">
          <div v-if="settings.right.addShow" class="gz-header-btn-group-item">
            <el-button v-has-permi="[permissions.create]" size="mini" @click="handleAdd">新增</el-button>
          </div>
          <div v-if="settings.right.importDataShow" v-has-permi="[permissions.import]" class="gz-header-btn-group-item">
            <el-upload
              ref="upload"
              :action="importActions"
              :with-credentials="true"
              :show-file-list="false"
              :limit="1"
              :on-success="handleImportSuccess"
              :on-error="handleImportError"
              :headers="headers">
              <el-button size="mini">导入数据</el-button>
            </el-upload>
          </div>
          <div v-if="settings.right.exportDataShow" v-has-permi="[permissions.export]" class="gz-header-btn-group-item">
            <el-button size="mini" @click="handleExport">导出数据</el-button>
          </div>
          <div v-if="settings.right.downloadTemplateShow" class="gz-header-btn-group-item">
            <el-button size="mini" @click="handleDownload">下载模板</el-button>
          </div>
          <div
            v-if="settings.right.paymentDetailsShow"
            v-has-permi="[permissions.check]"
            class="gz-header-btn-group-item">
            <el-button size="mini" @click="handleCheckDetails">缴费明细</el-button>
          </div>
          <div v-if="settings.right.more.length > 0" class="gz-header-btn-group-item">
            <el-dropdown size="mini" @command="handleCommand">
              <el-button size="mini">
                更多操作
                <i class="el-icon-arrow-down el-icon--right" />
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) in settings.right.more"
                  :key="'more' + index"
                  v-has-permi="[permissions[item.permission]]"
                  :command="item.emitEvent">
                  {{ item.label }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { getToken } from '@/utils/auth'
  export default {
    name: 'ContentHeader',
    props: {
      /**
       * 权限控制
       */
      permissions: {
        type: Object,
        default() {
          return {}
        }
      },

      /**
       * 上传url
       */
      importActions: {
        type: String,
        default: ''
      },

      /**
       * 头部配置数组
       */
      settings: {
        type: Object,
        default() {
          return {
            left: {
              // 是否显示总条目
              totalItemShow: true,
              // 是否显示搜索框
              searchShow: true,
              searchShows: true,
              searchShowis: true,
              // searchShows: true,
              // 是否显示筛选下拉
              filterToggleShow: true,
              // 是否显示表格字段选择
              tableHeadToggleShow: true
            },
            right: {
              // 是否显示刷新按键
              refresheShow: true,
              // 是否显示添加按键
              addShow: true,
              // 是否显示导入按键
              importDataShow: true,
              // 是否显示导出按键
              exportDataShow: true,
              // 是否显示下载模板按键
              downloadTemplateShow: true,
              // 缴费明细查询
              paymentDetailsShow: true,
              // 更多操作设置
              more: []
            },
            dropDownFilterArray: {
              // 筛选下拉筛选项
              arr: []
            },
            searchDateWords: {
              arr: []
            },
            searchPlaceholder: '请输入搜索关键字'
          }
        }
      },

      /**
       * table表头字段
       */
      tableHeadFields: {
        type: Array,
        default() {
          return []
        }
      },

      /**
       * 数据条目数
       */
      totalItem: {
        type: Number,
        default: 0
      },

      /**
       * handle操作
       */
      handle: {
        type: Array,
        default() {
          return []
        }
      },

      /**
       * 导入上传地址
       */
      importUrl: {
        type: String,
        default() {
          return ''
        }
      }
    },

    data() {
      return {
        headers: {},
        filterData: {},

        /**
         * 筛选下拉是否开启状态为
         */
        filterStatus: false,

        /**
         * 表格字段选择状态
         */
        fieldStatus: false
      }
    },
    mounted() {
      this.headers['Gz-token'] = getToken()
    },

    methods: {
      /**
       * 添加按钮点击事件处理
       */
      handleAdd() {
        this.$emit('add')
      },

      /**
       * 批量删除按钮点击事件处理
       */
      handleDeleteAll() {
        this.$emit('delete-all')
      },

      /**
       * 下载模板按钮点击事件处理
       */
      handleDownload() {
        this.$emit('download')
      },

      /**
       * 上传成功事件处理
       */
      handleImportSuccess(res) {
        if (res.result || res.result === true) {
          this.$emit('import-success')
        } else {
          console.log('res', res)
          if (res.message && res.message.length > 0) {
            this.noticeError('导入数据失败,失败原因:' + res.message)
          } else {
            this.noticeError('导入数据失败')
          }
          this.$emit('import-error', res)
        }
        this.$refs.upload.clearFiles()
      },

      /**
       * 上传失败事件处理
       */
      handleImportError(res) {
        this.noticeError('导入数据失败,失败原因:' + res.message || res.msg)
      },

      /**
       * 导出按钮点击事件处理
       */
      handleExport() {
        this.$emit('export')
      },

      /**
       * 刷新按钮点击事件处理
       */
      handleRefresh() {
        this.$emit('refresh')
      },

      /**
       * 设置按钮点击事件处理
       */
      handleSetting() {
        this.$emit('setting')
      },

      /**
       * 筛选框的值有变动
       */
      filterChange() {
        this.$emit('filter-change', this.filterData)
      },

      /**
       * 清除筛选框内容
       */
      filterClear(payload) {
        const _this = this
        this.$nextTick(() => {
          _this.filterData[payload] = null
          _this.$set(_this.filterData, payload, null)
        })
      },

      /**
       * 清除所有筛选框内容
       */
      filterClearAll() {
        this.filterData = {}
        console.log(this.filterData, '===---===')
        this.filterChange()
      },

      /**
       * 筛选下拉状态切换
       */
      toggleFilterStatus() {
        this.filterStatus = !this.filterStatus
      },

      /**
       * 表格字段按键状态切换
       */
      toggleFieldStatus() {
        this.fieldStatus = !this.fieldStatus
      },

      /**
       * 筛选dropdown隐藏处理
       */
      handleFilterDropHide() {
        this.filterStatus = false
      },

      /**
       * 表格字段筛选下拉框显影变化处理
       * @param {bool} e 出现则为 true，隐藏则为 false
       */
      handleFieldDropChange(e) {
        this.fieldStatus = e
      },

      /**
       * dropdown的指令事件
       */
      handleCommand(command) {
        this.$emit(command)
      },

      handleCheckDetails() {
        this.$emit('check')
      }
    }
  }
</script>
<style lang="scss" scoped="scoped">
  .gz-page-header {
    text-align: right;
    font-size: 12px;
    padding: 10px;

    .gz-filter {
      display: flex;
      justify-content: space-between;

      .gz-filter-left,
      .gz-filter-right {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .gz-filter-item {
          font-size: 12px;
          margin: 0 5px;
          color: #606266;
          ::v-deep .el-input-group__append {
            background: #faa13c;
            color: white;
          }

          .gz-search-buttons {
            position: absolute;
            left: 596px;
            top: 67px;
          }

          .gz-search-btn {
            position: absolute;
            left: 516px;
            top: 67px;
          }

          .gz-filter-svg-icon {
            display: flex;
            width: 28px;
            height: 26px;
            padding: 6px;
            border-radius: 5px;
            background: #f5f5f5;
            border: 1px solid #ccc;
            transition: color, background 0.25s ease-in-out;

            &:hover,
            &.is-active {
              background: #faa13c;
              border-color: white;
              fill: white;
            }
          }
        }

        .gz-filter-item:first-child {
          margin-left: 0;
          margin-right: 5px;
        }

        .gz-filter-item:last-child {
          margin-right: 0;
          margin-left: 5px;
        }

        .gz-filter-item-count {
          font-size: 14px;
          line-height: 16px;
          font-weight: 600;
        }
      }

      .gz-filter-right {
        .gz-filter-item {
          padding: 0 10px;
          border-right: 2px solid #ccc;
        }

        .gz-filter-item:last-child {
          border-right: none;
        }

        .gz-header-btn-group {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .gz-header-btn-group-item {
            margin: 0 5px;
          }

          .gz-header-btn-group-item:first-child {
            margin-left: 0;
          }

          .gz-header-btn-group-item:last-child {
            margin-right: 0;
          }

          ::v-deep .el-button {
            background: #f5f5f5;
            border: 1px solid #ccc;
            transition: color, background 0.25s ease-in-out;

            &:hover {
              background: #faa13c;
              border-color: white;
              color: white;
            }
          }
        }
      }
    }
  }

  .gz-dropdown-filter-wrapper {
    padding: 20px 10px;
    display: flex;
    align-items: center;
    .gz-dropdown-filter-item {
      margin: 0 5px;
      .names {
        display: inline-block;
        line-height: 40px;
      }
    }
    .gz-dropdown-filter-items {
      margin: 0 5px;
      // border: 1px solid black;
    }
  }
</style>
