/*
#***********************************************
#
#      Filename: src/utils/assist.js
#
#        Author: wwj - 318348750@qq.com
#   Description: 物管项目辅助函数库
#        Create: 2021-04-08 13:57:47
# Last Modified: 2021-04-08 14:23:17
# Last Modified: 2021-11-27 20:09:02
*/

/**
 * 递归过滤对象中为null/undefined/''/[]/{}的属性值
 */
export function clearDeep(obj) {
  if (!obj || !typeof obj === 'object') return

  const keys = Object.keys(obj)
  for (var key of keys) {
    const val = obj[key]
    if (typeof val === 'undefined' || ((typeof val === 'object' || typeof val === 'string') && !val)) {
      delete obj[key]
    } else if (typeof val === 'object') {
      clearDeep(obj[key])
      if (Object.keys(obj[key]).length === 0) {
        // 如某属性的值为不包含任何属性的独享，则将该属性删除
        delete obj[key]
      }
    }
  }
}
