/*
#***********************************************
#
#      Filename: src/views/mixins/pagination.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 分页混入
#        Create: 2021-11-10 16:24:00
# Last Modified: 2021-11-23 10:03:56
#***********************************************
*/
export default {
  data() {
    return {
      /**
       * 分页当前页码
       */
      currentPage: 1,

      /**
       * 分页大小
       */
      pageSize: 10,

      /**
       * 记录总数
       */
      total: 0
    }
  },

  watch: {
    /**
     * 当前页面发生变化
     */
    currentPage() {
      this.handleRefresh()
    },

    /**
     * 分页大小发生变化
     */
    pageSize() {
      this.handleRefresh()
    }
  },

  methods: {
    /**
     * 改变当前分页
     */
    changePage(payload) {
      this.currentPage = payload
    },

    /**
     * 改变当前分页大小
     */
    changePageSize(payload) {
      this.pageSize = payload
    },

    /**
     * 获取基本的筛选信息
     */
    createBaseQueryData() {
      return {
        page: this.currentPage,
        pageSize: this.pageSize
      }
    }
  }
}
