/*
#***********************************************
#
#      Filename: src/views/mixins/commonInfo.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 公共混入信息
#        Create: 2021-11-04 23:49:04
# Last Modified: 2021-11-28 11:02:21
#***********************************************
*/
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('user')

export default {
  computed: {
    ...mapState({
      uid: (state) => state.userInfo.userId,
      pid: (state) => state.currentParking.id,
      uName: (state) => state.userInfo.username,
      uType: (state) => state.userInfo.userType,
      userInfo: (state) => state.userInfo,
      parkingList: (state) => state.parkingList,
      operList: (state) => state.operList,
      communityId: (state) => state.currentParking.communityId,
      currentParking: (state) => state.currentParking
    })
  },

  methods: {
    ...mapActions({}),

    formatMobile(mobile) {
      if (mobile) {
        return mobile.replace(/(\d{3})(\d{4})(\d{4})/, '$1 $2 $3')
      } else {
        return ''
      }
    },
    formOperName(id) {
      if (this.operList) {
        const oper = this.operList.find((item) => item.id == id)
        if (oper && oper.name) {
          return oper.name
        } else {
          return '--'
        }
      }
    }
  },

  watch: {
    '$store.state.user.pid': function () {
      this.handleRefresh()
    }
  }
}
