/*
#***********************************************
#
#      Filename: src/views/mixins/modal.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 对话框相关混入
#        Create: 2021-11-10 11:21:08
# Last Modified: 2021-11-27 20:28:40
#***********************************************
*/
import { deepClone } from '@/utils'

export default {
  data() {
    return {
      /**
       * modal控制对象
       */
      modalObject: {
        currentAction: 'add',
        status: false,
        loading: false
      },

      /**
       * modal对话框表单数据绑定
       */
      actionDataItem: {}
    }
  },

  methods: {
    /**
     * 新增对话框
     * 需要设置对话框控制对象、对话框数据对象、对话框显影控制
     */
    add(title, cb = () => {}) {
      this.resetForm()
      this.modalObject.currentAction = 'add'
      this.modalObject.title = title
      this.modalObject.status = true
      this.actionDataItem = deepClone(this.formData)
      cb()
    },

    /**
     * 编辑对话框
     * 需要设置对话框控制对象、对话框数据对象、对话框显影控制
     */
    edit(dataObj, title, cb = () => {}) {
      this.resetForm()
      this.modalObject.currentAction = 'edit'
      this.modalObject.title = title
      this.modalObject.status = true
      const objectKey = Object.keys(this.formData)
      const filteredFormData = {}
      filteredFormData.id = dataObj.id
      objectKey.map((item) => {
        filteredFormData[item] = dataObj[item]
      })
      // this.actionDataItem = filteredFormData
      console.log('actionDataItem', filteredFormData)
      this.$set(this.$data, 'actionDataItem', filteredFormData)
      cb(dataObj)
    },

    // 付款
    pay(dataObj, title, cb = () => {}) {
      this.resetForm()
      this.modalObject.currentAction = 'pay'
      this.modalObject.title = title
      this.modalObject.status = true
      const objectKey = Object.keys(this.formData)
      const filteredFormData = {}
      filteredFormData.id = dataObj.id
      objectKey.map((item) => {
        filteredFormData[item] = dataObj[item]
      })
      // this.actionDataItem = filteredFormData
      this.$set(this.$data, 'actionDataItem', filteredFormData)
      cb(dataObj)
    },

    exportPdf(title) {
      this.modalObject.currentAction = 'exportPdf'
      this.modalObject.title = title
      this.modalObject.status = true
    },
    /**
     * 删除记录
     */
    handleDelete(id, title, cb = () => {}) {
      this.$confirm('您确认要删除所选项目吗？', title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          cb(id)
        })
        .catch((err) => {
          console.log(err)
        })
    },

    /**
     * 批量删除记录
     */
    handleBatchDelete(title, cb = () => {}) {
      this.$confirm('您确认要删除所选项目吗？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          cb(this.selectItems)
        })
        .catch((err) => {
          console.log(err)
        })
    },

    /**
     * 重置表单并关闭对话框
     */
    resetForm() {
      this.resetActionDataItem()
      this.modalObject.status = false
      this.modalObject.loading = false
    },

    /**
     * 重置对话框数据对象
     */
    resetActionDataItem() {
      this.actionDataItem = deepClone(this.formData)
    },

    /**
     * 取消按钮点击
     */
    cancel() {
      this.open = false
      this.modalObject.status = false
      this.modalObject.loading = false
    },

    /**
     * 过滤数据
     */
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => {
        return filterVal.map((j) => {
          return v[j]
        })
      })
    }
  }
}
