/*
#***********************************************
#
#      Filename: src/views/mixins/filter.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 筛选混入
#        Create: 2021-11-04 09:57:59
# Last Modified: 2021-11-23 16:01:14
#***********************************************
*/
import { baseUrl } from '@/utils/request'
import { clearDeep } from '@/utils/assist'

export default {
  data() {
    return {
      /**
       * 筛选项配置
       */
      filterData: {},

      /**
       * 选择框选择状态的item
       */
      selectItems: [],

      /**
       * table加载状态
       */
      loading: false,

      /**
       * 列表数据
       */
      dataList: []
    }
  },

  methods: {
    /**
     * 筛选条件改变
     */
    filterChange(payload) {
      this.filterData = payload
      this.handleRefresh()
    },

    /**
     * 数据表选择框状态改变
     */
    changeSelectItems(payload) {
      this.selectItems = payload
    },

    /**
     * 数据表刷新
     */
    handleRefresh() {
      this.getList()
    },

    /**
     * 上传成功
     */
    handleImportSuccess() {
      this.noticeSuccess('上传成功')
      this.handleRefresh()
    },

    /**
     * 上传失败
     */
    handleImportError(res) {
      this.noticeError(res.message || res.msg)
    },

    /**
     * 设置筛选菜单的筛选项
     * @param {array} res 筛选项列表数据
     * @param {string} field 筛选项的name
     * @param {object} el-option的value和label采用res中的哪个字段
     */
    setFilterSelectOptions(res, field, options = { value: 'code', label: 'name' }) {
      const index = this.filter.findIndex((item) => item.name === field)
      this.filter[index].options = []
      res.forEach((item) => {
        const data = {
          value: item[options.value],
          label: item[options.label]
        }
        this.filter[index].options.push(data)
      })
      this.filter[index].options.sort()
    },

    /**
     * 删除选择框选定的数据对象
     */
    deleteAll() {
      const dataArray = []
      this.selectItems.forEach((item) => {
        dataArray.push(item.id)
      })
      const data = { ids: dataArray }
      this.deleteRequest(data)
    },

    /**
     * 删除对应行的数据对象
     */
    deleteItem(dataObj) {
      const dataArray = []
      dataArray.push(dataObj.id)
      const data = { ids: dataArray }
      this.deleteRequest(data)
    },

    /**
     * 导出数据到excel
     */
    exportExcel() {
      console.log('导出数据')
      this.$confirm('是否确认导出所有数据？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const { queryData, subUrl } = this.exportQuery()
          clearDeep(queryData)
          const query = Object.keys(queryData)
            .map((key) => {
              if (queryData[key]) {
                return encodeURIComponent(key) + '=' + encodeURIComponent(queryData[key])
              }
            })
            .join('&')
          const url = baseUrl + subUrl + '?' + query
          const link = document.createElement('a')
          console.log('下载链接为', url)
          link.href = url
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
        .catch((err) => {
          console.log(err)
        })
    },

    // downloadTemplate() {
    //   console.log('下载模板')
    //   const url = baseUrl + subUrl + '?' + query
    //   const link = document.createElement('a')
    //   console.log('下载链接为', url)
    //   link.href = url
    //   document.body.appendChild(link)
    //   link.click()
    //   document.body.removeChild(link)
    // },

    /**
     * 删除对象中的空属性
     */
    removeEmpty(obj) {
      Object.keys(obj).forEach((key) => {
        if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
          delete obj[key]
        }
      })
      return obj
    }
  }
}
